import React from 'react'
import useMedia from 'use-media'
import { graphql } from 'gatsby'
import {
  H1,
  Flex,
  Billboard,
  Row,
  Column,
  Wrapper,
  LightSection,
  Button,
  SEO,
  FadeSwiper,
  Swiper,
} from '@renderbus/common/components'
import {
  BannerContainer,
  DashedArrow,
  DesList,
  EnterpriseRightContext,
  EnterpriseRightTitle,
  StudentSection,
  EnterpriseSection,
  EnterpriseInfoContainer,
  GocloudTitle,
  EducationH2,
  FlexFixSafari,
  OnlyForAnimation,
  DiamondIcon,
  MoreModeTitle,
  CompanyTitle,
} from './gocloud.atom'
import { color } from '@renderbus/common/theme'
import Layout from '../molecules/layout'
import StudentPriceTable from '../molecules/gocloud/student-price-table'
import {
  GoCloudIcon1,
  GoCloudIcon2,
  GoCloudIcon3,
  GoCloudIcon4,
  GoCloudIcon5,
  GoCloudIcon6,
} from '../molecules/icons'
import { API, Axios } from '@renderbus/common/utils'

const SwiperSwitch = ({ children, ...rest }) => {
  const isWide = useMedia({ minWidth: 768 })
  return isWide ? (
    <FadeSwiper {...rest} swiperHeight='600px'>
      {children}
    </FadeSwiper>
  ) : (
    <Swiper {...rest}>{children}</Swiper>
  )
}
const instance = new Axios(API.auth.host)
class Index extends React.PureComponent {
  redirectToAuth() {
    instance
      .post(API.auth.getAuthToken)
      .then(res => {
        if (res.code === 200) {
          window.open(`https://account.renderbus.com/authenticate?token=${res.data}`)
        } else {
          throw res
        }
      })
      .catch(e => {
        console.error(e)
        window.location.href = 'https://task.renderbus.com/sso'
      })
  }
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='学生、教职员工、企业专享云渲染优惠活动-Renderbus云渲染农场'
          keywords='云渲染充值优惠,学生云渲染折扣,学生动画云渲染'
          description='为鼓励创作,推动CG行业发展,瑞云渲染农场为学生、教职员工、小团队、企业等提供专属5折云渲染优惠福利,提供相应资料,一键认证即可享最低折扣价渲染,立即注册领取吧。'
        />
        <div style={{ position: 'relative' }}>
          <SwiperSwitch navBottom={30}>
            <BannerContainer>
              <Flex>
                <OnlyForAnimation>
                  <DiamondIcon />
                  动画用户专享
                </OnlyForAnimation>
                <H1>教育优惠计划</H1>
                <EducationH2>小团队也能实现大梦想</EducationH2>
                <DesList>
                  <span>技术支持</span>
                  <span>专业指导</span>
                  <span>渲染优惠</span>
                  <span>宣传机会</span>
                </DesList>
                <Button
                  as='a'
                  variant='outlined'
                  href='#price-section'
                  style={{ margin: '25px 0 -50px' }}
                >
                  查看权益
                </Button>
              </Flex>
              <Billboard fluid={data.banner.childImageSharp.fluid} />
            </BannerContainer>
            <BannerContainer>
              <Flex>
                <OnlyForAnimation>
                  <DiamondIcon />
                  动画用户专享
                </OnlyForAnimation>
                <CompanyTitle>企业用户权益</CompanyTitle>
                <MoreModeTitle style={{ letterSpacing: '12px' }}>开启更多优惠模式</MoreModeTitle>
                <Button
                  as='a'
                  variant='outlined'
                  href='#enterprise-section'
                  style={{ margin: '30px 0 -30px' }}
                >
                  查看权益
                </Button>
              </Flex>
              <Billboard fluid={data.banner2.childImageSharp.fluid} />
            </BannerContainer>
          </SwiperSwitch>
        </div>
        <LightSection id='price-section'>
          <Wrapper>
            <GocloudTitle style={{ marginBottom: '20px' }}>学生、教职员工认证福利</GocloudTitle>
            <p style={{ marginBottom: '50px', textAlign: 'center', color: 'white' }}>
              瑞云渲染农场为激发CG专业学生的无限潜能，针对动画用户特推出教育优惠政策，学生和教职员工提供相应资料可享受
              <span style={{ color: color.primary }}>最低折扣价（钻石会员）</span>
            </p>
            <StudentPriceTable />
          </Wrapper>
        </LightSection>
        <StudentSection id='student-section'>
          <Wrapper>
            <GocloudTitle>教育优惠资格</GocloudTitle>
            <Row style={{ textAlign: 'center', margin: '0 0 60px' }}>
              <Column xs='12' lg='4'>
                <FlexFixSafari style={{ height: '120px' }}>
                  <GoCloudIcon4 style={{ width: '124px' }} />
                  <DashedArrow />
                </FlexFixSafari>
                <EnterpriseInfoContainer>
                  <EnterpriseRightTitle>登记认证信息</EnterpriseRightTitle>
                  <EnterpriseRightContext>
                    身份证扫描件
                    <br />
                    学生证或教师证扫描件
                  </EnterpriseRightContext>
                </EnterpriseInfoContainer>
              </Column>
              <Column xs='12' lg='4'>
                <FlexFixSafari style={{ height: '120px' }}>
                  <GoCloudIcon5 style={{ width: '110px' }} />
                  <DashedArrow />
                </FlexFixSafari>
                <EnterpriseInfoContainer>
                  <EnterpriseRightTitle>认证审核</EnterpriseRightTitle>
                </EnterpriseInfoContainer>
              </Column>
              <Column xs='12' lg='4'>
                <FlexFixSafari style={{ height: '120px' }}>
                  <GoCloudIcon6 style={{ width: '114px' }} />
                </FlexFixSafari>
                <EnterpriseInfoContainer>
                  <EnterpriseRightTitle>尽享低价渲染</EnterpriseRightTitle>
                </EnterpriseInfoContainer>
              </Column>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <Button
                as='a'
                href='https://task.renderbus.com/center/user/profile'
                style={{ marginRight: '20px' }}
                target='nofollow'
                backgroundColor={color.primary}
              >
                立即认证
              </Button>
              <Button
                as='a'
                color='primary'
                variant='outlined'
                href='https://task.renderbus.com/sso/register'
                rel='nofollow'
              >
                免费试用
              </Button>
            </div>
          </Wrapper>
        </StudentSection>
        <EnterpriseSection id='enterprise-section'>
          <Wrapper>
            <GocloudTitle>企业用户权益</GocloudTitle>
            <Row style={{ textAlign: 'center', margin: '0 0 60px' }}>
              <Column xs='12' lg='4'>
                <GoCloudIcon1 style={{ width: '110px' }} />
                <EnterpriseInfoContainer>
                  <EnterpriseRightTitle>企业价格</EnterpriseRightTitle>
                  <EnterpriseRightContext>享受更超值的优惠价格</EnterpriseRightContext>
                </EnterpriseInfoContainer>
              </Column>
              <Column xs='12' lg='4'>
                <GoCloudIcon2 style={{ width: '110px' }} />
                <EnterpriseInfoContainer>
                  <EnterpriseRightTitle>渲染额度</EnterpriseRightTitle>
                  <EnterpriseRightContext>享受更超值的渲染额度</EnterpriseRightContext>
                </EnterpriseInfoContainer>
              </Column>
              <Column xs='12' lg='4'>
                <GoCloudIcon3 style={{ width: '110px' }} />
                <EnterpriseInfoContainer>
                  <EnterpriseRightTitle>主子账户权益</EnterpriseRightTitle>
                  <EnterpriseRightContext>享受更便捷的协同办公方式</EnterpriseRightContext>
                </EnterpriseInfoContainer>
              </Column>
            </Row>
            <Flex>
              <Button
                onClick={this.redirectToAuth}
                style={{ marginRight: '20px' }}
                target='nofollow'
                backgroundColor={color.primary}
              >
                立即认证
              </Button>
              <Button
                as='a'
                color='primary'
                variant='outlined'
                href='https://task.renderbus.com/sso/register'
                rel='nofollow'
              >
                免费试用
              </Button>
            </Flex>
          </Wrapper>
        </EnterpriseSection>
      </Layout>
    )
  }
}

export default Index

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/gocloud/bg.png/" }) {
      ...fluidImage
    }
    banner2: file(relativePath: { regex: "/gocloud/gocloud-bg.png/" }) {
      ...fluidImage
    }
  }
`
